<template>
    <div class="detail__container">
        <v-progress-linear v-if="!isEntryFetched" class="progress-bar" indeterminate color="red" />
        <div class="container-fluid px-0" v-else>
            <div class="row m-0 border-top border-black pb-md-12">
                <div class="col-12 p-0 bg-black">
                    <div class="d-flex flex-row align-items-center wagesdetail-bar-back">
                        <div class="ps-8">
                            <span class="wagesdetail-span-back" @click="onBackClick">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.726" height="33.451" viewBox="0 0 17.726 33.451">
                                    <path id="Path_54" data-name="Path 54" d="M153.418,277.666l-15.311-15.311,15.311-15.311" transform="translate(-137.107 -245.629)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                </svg>
                                <span class="ps-4">Back</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center row m-0 px-2 px-md-8">
                <div class="row d-flex flex-row align-items-stretch px-0">
                    <div class="col-md-4 d-flex flex-column align-items-start pt-3 pt-md-0 ">
                        <div class="pb-10 pb-md-0 flex-grow-1">
                            <div class="wagesdetail-main-title">
                                {{ entry.tradeName }}
                            </div>
                            <div class="wagesdetail-main-address pt-4">
                                {{ entry.address }}
                            </div>
                        </div>
                        <div class="d-flex flex-row wage-detail-aux-height w-100" style="padding-bottom: 15px;">
                            <div class="w-100 border-bottom border-black">&nbsp;</div>
                        </div>
                        <div class="d-flex flex-row pb-5">
                            <div class="wagesdetail-main-tellus">Tell us your wage theft story</div>
                        </div>
                        <div class="d-flex flex-row">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfmTqtftmD6iEG8o4Ab3Grb5iOn8iH-0br-VNOYTDV6KqwPaw/viewform?usp=sf_link" target="_blank" class="btn btn-wage-detail-enternow-new">Enter now</a>
                        </div>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                    <div class="col-md-7 d-flex flex-column px-md-0">
                        <div class="d-flex wage-detail-aux-height">
                            <div class="w-100 border-bottom border-black">&nbsp;</div>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-between py-7 pe-0 pe-md-10">
                            <div class="">
                                <div class="wage-detail-company-numbers">{{ entry.cases.length || 0 }}</div>
                            </div>
                            <div class="pe-0 pe-md-10">
                                <div class="wage-detail-company-numbers-sub">Cases filed<br> against business</div>
                            </div>
                        </div>
                        <div class="d-flex wage-detail-aux-height">
                            <div class="w-100 border-bottom border-black">&nbsp;</div>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-between py-7 pe-0 pe-md-10">
                            <div class="">
                                <div class="wage-detail-company-numbers">${{ Math.floor(entry.entityAggs.wagesStolen) }}</div>
                            </div>
                            <div class="pe-0 pe-md-10">
                                <div class="wage-detail-company-numbers-sub">Wages Stolen</div>
                            </div>
                        </div>
                        <div class="d-flex wage-detail-aux-height">
                            <div class="w-100 border-bottom border-black">&nbsp;</div>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-between py-7 pe-0 pe-md-10">
                            <div class="">
                                <div class="wage-detail-company-numbers">{{ entry.entityAggs.peopleAffected }}</div>
                            </div>
                            <div class="pe-0 pe-md-10">
                                <div class="wage-detail-company-numbers-sub">Workers who<br>lost wages</div>
                            </div>
                        </div>
                        <div class="d-flex flex-row wage-detail-aux-height">
                            <div class="w-100 border-bottom border-black">&nbsp;</div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="w-100">
                                <div class="wage-detail-company-data-for-all mt-2 mb-2">Contact us</div>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="w-100">
                                <div class="d-flex flex-md-row wage-detail-mobile-flex justify-content-between align-items-center">
                                    <div class="d-flex flex-column wage-detail-company-name mt-2 mb-2 mb-7 mb-md-0 me-4 pb-0 pe-4">
                                        <a href="mailto:info@documentedny.com " target="_blank" class="btn btn-wage-detail-addcomplaint-link">email</a>
                                    </div>
                                    <div class="d-flex flex-column wage-detail-company-name mt-2 mb-2 mb-7 mb-md-0 me-4 pb-0 pe-4">
                                        <a href="https://api.whatsapp.com/send?phone=13476039096" target="_blank" class="btn btn-wage-detail-addcomplaint-link">whatsapp</a>
                                    </div>
                                    <div class="d-flex flex-column wage-detail-company-name mt-2 mb-2 mb-7 mb-md-0 me-4 pb-0 pe-4">
                                        <a href="tel:13476039096" target="_blank" class="btn btn-wage-detail-addcomplaint-link">phone</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
  .border-black{
      border-color:#000 !important;
  }
  .wage-detail-image-building{
    height: auto;
  }
  .wage-detail-company-data-for-all{
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
  }
  .wage-detail-aux-height{
    height:1px;
  }
  .wage-detail-company-numbers{
    font-size: 3rem;
    line-height: 3rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
  }
  .wage-detail-company-numbers-sub{
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    line-height: 1.2rem;
    min-width: 13rem;
  }
  a.btn-wage-detail-addcomplaint-link{
    font-family: 'Poppins', sans-serif;
    padding: 10px 20px 10px 20px;
    border: 1px solid #D91F2A;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: .7rem;
    line-height: 1.5rem !important;
    font-weight: 600;
    color: #D91F2A !important;
    width: 9rem;
    height: 3rem;
  }
  a:hover.btn-wage-detail-addcomplaint-link{
    font-family: 'Poppins', sans-serif;
    padding: 10px 20px 10px 20px;
    border: 1px solid #D91F2A;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: .7rem;
    line-height: 1.5rem !important;
    font-weight: 600;
    color: #fff !important;
    background-color:#D91F2A;
    width: 9rem;
    height: 3rem;
  }
  a.btn-wage-detail-enternow{
    font-family: 'Poppins', sans-serif;
    padding: 10px 20px 10px 20px;
    border: 1px solid #D91F2A;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 600;
    color: #fff !important;
    background:#D91F2A;
    width: 13rem;
    height: 3rem;
    line-height: 1.4rem !important;
  }
  a.btn-wage-detail-enternow-new{
    font-family: 'Poppins', sans-serif;
    padding: 10px 20px 10px 20px;
    border: 1px solid #D91F2A;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 600;
    color: #fff !important;
    background:#D91F2A;
    width: 13rem;
    height: 3rem;
    line-height: 1.4rem !important;
  }
  .wage-detail-company-name{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    line-height: 1.1rem;
    padding-bottom: .5rem;
    font-weight:600;
    min-width: 8rem;
  }
  .wagesdetail-bar-back{
    height:64px;
  }
  .wagesdetail-span-back{
    color:white;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
    font-size: .8rem !important;
    line-height: 1.3rem !important;
  }
  .wagesdetail-main-title{
    font-family: 'Poppins',sans-serif;
    color: #000;
    font-weight: 600;
    font-size: 3rem;
    text-align: left;
    line-height: 3rem;
  }
  .wagesdetail-main-address{
    font-family: 'Poppins',sans-serif;
    color: #000;
    font-weight: 500;
    font-size: .9rem;
    text-align: left;
    line-height: .9rem;
  }
  .wagesdetail-main-tellus{
    font-family: 'Poppins',sans-serif;
    color: #000;
    font-weight: 600;
    font-size: .9rem;
    text-align: left;
    line-height: .9rem;
  }
  @media (max-width: 576px) {
    .wage-detail-company-name{
      min-width: 0rem;
    }
    .wage-detail-company-numbers{
      font-size: 2rem;
      line-height: 2rem;
    }
    .wage-detail-image-building{
      height: 7rem;
    }
    .wage-detail-mobile-flex{
      flex-direction: column !important;
    }
  }
</style>
<script>
import { isNil } from 'lodash'
import { api } from '../util/api'

export default {
    name: 'WagesDetail',
    data () {
        return {
            entry: null,
            headers: [],
        }
    },
    computed: {
        isEntryFetched () {
            return !isNil(this.entry)
        },
        cases () {
            return (this.entry?.cases || []).map(c => ({ ...c, violations: c.violations.join('\n') }))
        },
    },
    created () {
        this.fetchCase()

        this.headers = [
            { text: 'Case id', value: 'caseId' },
            { text: 'Case violations', value: 'numCaseViolations' },
            { text: 'Wages stolen', value: 'amtBackwages' },
            { text: 'People affected', value: 'amtEmployees' },
            { text: 'Violations', value: 'violations' },
        ]
    },
    methods: {
        fetchCase () {
            const slug = this.$route.params.slug
            return api.get('find', { slug })
                .then(entry => this.entry = entry)
        },
        onBackClick () {
            if (window.history.length === 1) {
                this.$router.push('/search')
            } else {
                window.history.back()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.detail {
    max-width: 1200px;
    padding: 0 30px;
    width: 100%;
    margin: 0 auto;
    padding: 120px 0;

    &__container {
        background-color:#fff;
    }
    &__back {
        font-size: 16px;
        font-weight: bold;
        border: 2px solid black;
        background-color: white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 95px;
        height: 50px;
        position: fixed;
        z-index: 5;
        left: 12px;
        top: 103px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
    }
    &__topbar {
        height: 62px;
        background: linear-gradient(90deg, #1F262F 0%, #DADCDF 100%);
        border-radius: 3px 3px 0px 0px;
    }
    &__flex {
        display: flex;
    }
    &__address {
        color: #4f5764;
        margin-left: 24px;
        font-size: 20px;
        margin-top: 21px;
    }
    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f2f2;
        margin-left: 18px;
        margin-right: 13px;
        transform: translateY(-50%);
        width: 92px;
        height: 92px;
    }
    &__trade-name {
        color: $charcoal;
        font-size: 24px;
        font-weight: 700;
        margin-top: 17px;
    }
    &__stats-container {
        display: flex;
        padding-bottom: 20px;
    }
    &__row {
        display: flex;
    }
    &__add-complaint {
        background-color: $blue;
        margin: auto 100px;
        color: white;
        width: 158px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
    }
    &__stat {
        width: 140px;
    }
    &__stat:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.14);
    }
    &__stat-number {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        width: 100%;
    }
    &__stat-desc {
        color: $blue;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }
    &__card-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        grid-template-areas:
            "complaints contact"
            "complaints resources";
    }
    &__complaints {
          grid-area: complaints;
    }
    &__contact {
        grid-area: contact;
    }
    &__resources {
        grid-area: resources;
    }
}
.details {
    width: 100%;
    max-width: 1280px;
}
#map {
    height: 256px;
    width: 100%;
    margin: 0;
}
</style>

<style lang="scss">
.detail__table td {
    white-space: pre;
}
</style>
