<template>
    <div class="detail__container">
        <v-progress-linear v-if="!isEntryFetched" class="progress-bar" indeterminate color="accent" />
        <div class="detail" v-else>
            <div class="detail__back" @click="onBackClick">Back</div>

            <v-card class="detail__info">
                <div class="detail__topbar" />
                <div class="detail__flex">
                    <div class="detail__img">
                        <img src="../assets/building.png">
                    </div>
                    <div class="detail__trade-name">{{ entry.ownerBusinessName }}</div>
                    <div class="detail__address">{{ entry.address }}, {{ entry.zipcode }} {{ entry.borough }}, New York</div>
                </div>
                <div class="detail__owner">Owner: {{ entry.owner }}</div>
                <div class="detail__row">
                    <div class="detail__stats-container">
                        <div class="detail__stat">
                            <div class="detail__stat-number">{{ entry.cases.length || 0 }}</div>
                            <div class="detail__stat-desc">Number of complaints</div>
                        </div>
                        <!-- <div class="detail__stat">
                            <div class="detail__stat-number">${{ Math.floor(entry.entityAggs.wagesStolen) }}</div>
                            <div class="detail__stat-desc">Wages Stolen</div>
                        </div>
                        <div class="detail__stat">
                            <div class="detail__stat-number">{{ entry.entityAggs.peopleAffected }}</div>
                            <div class="detail__stat-desc">People Affected</div>
                        </div> -->
                    </div>

                    <v-btn class="detail__add-complaint" color="blue" disabled>Add a Complaint</v-btn>
                </div>
            </v-card>

            <div class="detail__card-grid">
                <v-card class="detail__complaints">
                    <v-card-title>Wage Complaints</v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="entry.cases"
                            :items-per-page="1000"
                            hide-default-footer
                        />
                    </v-card-text>
                </v-card>

                <v-card class="detail__contact">
                    <v-card-title>Contact us</v-card-title>
                    <v-card-text>
                        <div>Email:</div>
                        <div>Whatsapp:</div>
                        <div>Phone:</div>
                    </v-card-text>
                </v-card>

                <v-card class="detail__resources">
                    <v-card-title>Resources</v-card-title>
                </v-card>

                <!-- <v-card class="detail__trade-aggs">
                    <v-card-title>Data for all {{ entry.tradeName }}</v-card-title>
                    <div class="detail__stats-container">
                        <div class="detail__stat">
                            <div class="detail__stat-number">{{ entry.tradenameAggs.complaintCount }}</div>
                            <div class="detail__stat-desc">Number of complaints</div>
                        </div>
                        <div class="detail__stat">
                            <div class="detail__stat-number">${{ Math.floor(entry.tradenameAggs.wagesStolen) }}</div>
                            <div class="detail__stat-desc">Wages Stolen</div>
                        </div>
                        <div class="detail__stat">
                            <div class="detail__stat-number">{{ entry.tradenameAggs.peopleAffected }}</div>
                            <div class="detail__stat-desc">People Affected</div>
                        </div>
                    </div>
                </v-card> -->
            </div>
        </div>
    </div>
</template>

<script>
import { isNil } from 'lodash'
import { api } from '../util/api'

export default {
    name: 'PermitsDetail',
    data () {
        return {
            entry: null,
            headers: [],
        }
    },
    computed: {
        isEntryFetched () {
            return !isNil(this.entry)
        },
    },
    created () {
        this.fetchCase()

        this.headers = [
            { text: 'Job id', value: 'jobId' },
            { text: 'Address', value: 'address' },
            { text: 'Zipcode', value: 'zipcode' },
            { text: 'Borough', value: 'borough' },
        ]
    },
    methods: {
        fetchCase () {
            const slug = this.$route.params.slug
            return api.get('findJob', { slug })
                .then(entry => this.entry = entry)
                .then(console.log)
                .catch(console.warn)
        },
        onBackClick () {
            if (window.history.length === 1) {
                this.$router.push('/search')
            } else {
                window.history.back()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.detail {
    max-width: 1200px;
    padding: 0 30px;
    width: 100%;
    margin: 0 auto;
    padding: 120px 0;

    &__container {
        padding: 32px;
        background-image: url('../assets/nyc.jpeg');
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
    }
    &__back {
        font-size: 16px;
        font-weight: bold;
        border: 2px solid black;
        background-color: white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 95px;
        height: 50px;
        position: fixed;
        z-index: 5;
        left: 12px;
        top: 103px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
    }
    &__topbar {
        height: 62px;
        background: linear-gradient(90deg, #1F262F 0%, #DADCDF 100%);
        border-radius: 3px 3px 0px 0px;
    }
    &__flex {
        display: flex;
    }
    &__address {
        color: #4f5764;
        margin-left: 24px;
        font-size: 20px;
        margin-top: 21px;
    }
    &__owner {
        color: #4f5764;
        font-size: 24px;
        margin-left: 123px;
        margin-bottom: 18px;
    }
    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f2f2;
        margin-left: 18px;
        margin-right: 13px;
        transform: translateY(-50%);
        width: 92px;
        height: 92px;
    }
    &__trade-name {
        color: $charcoal;
        font-size: 24px;
        font-weight: 700;
        margin-top: 17px;
    }
    &__stats-container {
        display: flex;
        padding-bottom: 20px;
    }
    &__row {
        display: flex;
    }
    &__add-complaint {
        background-color: $blue;
        margin: auto 100px;
        color: white;
        width: 158px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
    }
    &__stat {
        width: 140px;
    }
    &__stat:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.14);
    }
    &__stat-number {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        width: 100%;
    }
    &__stat-desc {
        color: $blue;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }
    &__card-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        grid-template-areas:
            "complaints contact"
            "complaints resources";
    }
    &__complaints {
          grid-area: complaints;
    }
    &__contact {
        grid-area: contact;
    }
    &__resources {
        grid-area: resources;
    }
}
.details {
    width: 100%;
    max-width: 1280px;
}
#map {
    height: 256px;
    width: 100%;
    margin: 0;
}
</style>
